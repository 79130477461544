import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 810.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,810.000000) scale(0.100000,-0.100000)">


<path d="M0 4050 l0 -4050 5400 0 5400 0 0 4050 0 4050 -5400 0 -5400 0 0
-4050z m3819 1574 c11 -3 12 -50 7 -262 -4 -141 -9 -294 -12 -339 -5 -72 -4
-84 11 -90 10 -3 27 -1 38 6 33 21 207 62 287 68 43 3 108 0 150 -7 125 -21
279 -114 345 -210 15 -22 33 -40 39 -40 6 0 41 21 77 48 36 26 114 81 173 122
234 164 352 249 369 264 10 10 17 31 17 52 0 28 8 44 35 71 31 31 40 35 72 30
45 -8 70 -31 87 -82 18 -50 6 -99 -33 -139 -30 -31 -31 -36 -31 -117 l0 -84
66 -19 c131 -38 251 -143 323 -283 27 -53 71 -223 71 -274 0 -31 4 -39 19 -39
15 0 21 12 30 63 28 146 74 240 161 327 104 105 213 147 348 137 251 -19 436
-226 445 -497 1 -41 -4 -211 -12 -377 l-14 -303 -74 0 c-79 0 -77 -1 -73 62 1
15 -1 37 -5 47 -6 19 -9 18 -53 -13 -77 -55 -183 -103 -243 -110 -97 -12 -203
26 -291 103 -25 23 -53 41 -61 41 -8 0 -27 -15 -42 -32 -15 -18 -75 -85 -134
-148 -58 -63 -139 -150 -179 -193 -59 -63 -72 -84 -67 -101 27 -89 7 -190 -49
-249 -39 -42 -72 -57 -122 -57 -89 0 -174 98 -174 201 0 81 83 199 151 213 36
7 96 -2 105 -15 19 -31 62 8 311 286 44 50 97 106 117 124 20 19 36 40 36 46
0 6 -11 33 -24 61 -34 71 -44 108 -59 209 -9 67 -16 90 -27 90 -9 0 -18 -15
-23 -40 -64 -302 -198 -465 -421 -512 -114 -24 -303 5 -419 63 -35 18 -52 22
-57 14 -4 -6 -10 -123 -15 -258 -4 -136 -10 -302 -14 -370 l-6 -123 -92 -5
-93 -5 0 63 c1 35 5 160 10 278 5 118 14 391 20 605 6 215 15 424 21 465 41
286 215 487 456 524 l78 12 3 93 c3 90 2 92 -19 89 -11 -2 -159 -102 -328
-223 l-307 -219 25 -71 c23 -63 26 -84 26 -215 0 -120 -4 -159 -23 -228 -33
-121 -78 -207 -145 -277 -32 -33 -53 -50 -47 -37 6 12 7 22 3 21 -4 -1 -25 -1
-45 0 -31 1 -38 5 -38 22 0 12 -4 18 -11 13 -16 -9 -30 18 -17 33 8 10 5 13
-13 14 l-24 0 22 11 c13 6 28 24 35 39 7 16 16 29 19 29 4 0 21 30 39 67 17
37 36 70 41 74 38 23 37 328 -1 374 -9 11 -36 -4 -133 -74 -111 -79 -122 -90
-124 -120 -3 -72 -55 -131 -114 -131 -39 0 -92 41 -109 85 -18 48 -12 88 20
126 42 52 78 64 125 45 l38 -16 66 47 c36 27 90 64 118 83 31 20 53 43 53 53
0 25 -77 99 -130 124 -102 47 -199 50 -365 9 -66 -16 -195 -62 -207 -74 -13
-14 -12 -424 2 -475 7 -26 10 -50 7 -53 -8 -8 -27 61 -33 121 -8 68 -23 105
-44 105 -10 0 -23 7 -30 15 -7 8 -19 15 -26 15 -8 0 -24 5 -37 12 -12 6 -29
14 -37 18 -13 6 -15 38 -12 216 2 115 7 304 12 419 5 116 12 287 16 382 l7
172 86 -5 c47 -3 92 -8 100 -10z m3254 -871 c-7 -2 -21 -2 -30 0 -10 3 -4 5
12 5 17 0 24 -2 18 -5z m81 -180 c-5 -98 -9 -277 -9 -398 0 -208 1 -222 22
-265 27 -54 75 -87 145 -100 101 -19 166 19 220 128 30 61 33 75 34 162 1 100
10 334 20 508 l6 102 65 0 65 0 -6 -122 c-4 -68 -11 -235 -16 -373 -11 -271
-19 -318 -71 -415 -38 -72 -98 -128 -173 -161 l-53 -24 -14 -295 -14 -295 -78
-3 -79 -3 6 38 c3 21 8 106 11 188 3 83 8 200 11 261 l6 111 -58 33 c-103 58
-173 158 -195 281 -10 54 -10 122 0 316 14 306 21 384 32 358 7 -19 8 -19 8 3
1 13 7 22 16 22 9 0 18 7 21 15 4 8 15 15 25 15 15 0 19 7 19 39 0 22 -5 42
-12 44 -7 3 2 6 21 6 l33 1 -8 -177z m-3300 -470 c9 -18 14 -33 12 -33 -6 0
-36 55 -36 66 0 11 5 3 24 -33z m3240 -663 l1 -45 52 -3 c50 -3 52 -4 51 -32
-1 -29 -2 -30 -53 -30 l-52 0 -7 -61 c-9 -94 -7 -154 6 -167 7 -7 29 -12 50
-12 36 0 38 -2 38 -30 0 -29 -2 -30 -44 -30 -81 0 -99 38 -91 199 8 152 13
255 14 259 1 2 9 2 18 0 12 -2 17 -14 17 -48z m-1812 -35 c2 -3 3 -18 0 -35
-4 -27 -8 -30 -38 -30 -86 0 -134 -49 -134 -136 0 -85 42 -127 128 -128 30 0
32 -2 32 -33 0 -32 -1 -33 -42 -33 -121 0 -204 151 -150 272 21 49 92 113 132
121 38 7 66 8 72 2z m658 -10 c36 -19 67 -58 75 -97 4 -17 4 -87 0 -154 -7
-118 -8 -124 -29 -124 -21 0 -21 2 -15 122 7 124 1 158 -28 190 -29 33 -106
18 -133 -25 -8 -14 -15 -70 -19 -157 -6 -126 -7 -135 -26 -135 -18 0 -20 8
-23 64 -5 96 14 231 36 261 26 35 80 70 109 70 13 0 37 -7 53 -15z m343 -1
c23 -11 41 -31 55 -60 19 -39 21 -54 15 -179 -6 -131 -7 -136 -27 -133 -12 2
-20 8 -19 14 10 56 13 234 4 256 -24 63 -112 76 -148 21 -15 -23 -30 -142 -32
-265 -1 -22 -6 -28 -22 -28 -20 0 -21 3 -14 133 8 152 20 186 78 228 46 33 63
35 110 13z m375 -13 c26 -18 70 -87 61 -95 -17 -15 -193 -126 -201 -126 -5 0
-8 10 -6 22 2 15 27 38 75 70 76 50 83 66 38 86 -30 14 -83 5 -109 -17 -63
-55 -61 -196 3 -231 11 -5 37 -10 59 -10 51 0 89 30 98 75 5 28 11 35 30 35
30 0 30 -14 -1 -76 -83 -165 -275 -104 -275 87 0 79 20 122 78 166 52 39 108
44 150 14z m332 -5 c0 -33 -13 -46 -44 -46 -32 0 -70 -21 -93 -50 -7 -8 -13
-41 -13 -73 0 -52 4 -62 29 -88 22 -21 39 -29 65 -29 33 0 36 -2 36 -30 0 -29
-2 -30 -45 -30 -37 0 -50 5 -78 34 -75 75 -70 213 10 287 34 32 47 37 96 38
26 1 37 -3 37 -13z"/>
<path d="M3782 5560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5262 4690 c-68 -31 -109 -67 -152 -135 -59 -95 -80 -181 -89 -370
-5 -107 -4 -165 4 -174 22 -31 132 -93 209 -117 116 -38 222 -38 299 -1 65 32
106 76 146 155 33 66 57 160 45 172 -5 5 -61 10 -124 11 l-115 3 -37 -37 c-43
-44 -74 -47 -117 -12 -61 52 -64 130 -6 194 l32 36 4 145 4 145 -30 3 c-16 1
-49 -7 -73 -18z"/>
<path d="M5436 4684 c-3 -9 -6 -74 -6 -145 0 -128 0 -129 31 -172 l31 -45 117
-4 c64 -3 119 -4 122 -2 13 7 -11 124 -37 179 -33 72 -111 153 -176 183 -57
26 -74 27 -82 6z"/>
<path d="M3773 4620 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M6360 4627 c-94 -26 -168 -94 -209 -192 -25 -61 -42 -133 -31 -140 4
-2 56 -7 116 -11 l109 -7 37 37 c39 39 62 44 96 20 32 -22 52 -67 52 -115 0
-34 -6 -47 -35 -76 -31 -31 -39 -34 -73 -28 -37 6 -39 4 -107 -71 -103 -114
-102 -111 -74 -141 43 -45 106 -68 189 -68 67 0 82 4 140 35 35 19 91 60 124
90 l59 56 8 149 c12 209 -8 301 -86 380 -69 71 -216 110 -315 82z"/>
<path d="M4741 4324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4610 4196 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6110 4185 c0 -35 32 -151 46 -170 15 -19 19 -17 100 70 46 49 83 94
81 100 -2 6 -49 11 -115 13 -97 2 -112 0 -112 -13z"/>
<path d="M7133 4685 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
